import React from 'react';
import MainText from '../../components/Home/MainText/MainText';
import './Home.css';
import '../../assets/styles/Waves.css';
import ManagedBy from '../../components/Home/ManagedBy/ManagedBy';

const Home = () => {
  return (
    <div className="home-container">
    <div className="waves-container">
      <div className="waves"></div>
    </div>
    <MainText />
    <ManagedBy />
  </div>
  );
}

export default Home;
