import React from 'react';
import Typewriter from 'typewriter-effect';
import './MainText.css';

const MainText = () => {
  return (
    <div className="main-text-container">
      <h1 className="main-title">LazyCoderz</h1>
      <div className="typewriter-text">
        <Typewriter
          options={{
            strings: ['Building next-generation software'],
            autoStart: true,
            loop: true,
          }}
        />
      </div>
    </div>
  );
}

export default MainText;
