import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { GitHub, LinkedIn, Instagram, YouTube } from '@mui/icons-material';
import './ManagedBy.css';

const ManagedBy = () => {
  return (
    <Box className="managed-by-container">
      <Typography variant="h4" component="div" className="managed-by-text">
        Managed By Binod Thapa
      </Typography>
      <Box className="icons-container">
        <IconButton component="a" href="https://github.com/lazycoderz-studio" target="_blank" rel="noopener noreferrer">
          <GitHub fontSize="large" />
        </IconButton>
        <IconButton component="a" href="https://www.linkedin.com/in/binod-thapa-35a436121" target="_blank" rel="noopener noreferrer">
          <LinkedIn fontSize="large" />
        </IconButton>
        <IconButton component="a" href="https://www.instagram.com/er.binodthapa" target="_blank" rel="noopener noreferrer">
          <Instagram fontSize="large" />
        </IconButton>
        <IconButton component="a" href="https://www.youtube.com/@LazyCoderz" target="_blank" rel="noopener noreferrer">
          <YouTube fontSize="large" />
        </IconButton>
      </Box>
    </Box>
  );
}

export default ManagedBy;
